import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronUp,
    faChevronDown
} from "@fortawesome/free-solid-svg-icons";

import { editorDetailsContext } from "../../context/GlobalContext";
const Collapsible = ({ open, children, title, visibilityBased = false, xs = "", reverseArrow = false }) => {
    const [isOpen, setIsOpen] = useState(open);

    const { darkMode } = useContext(editorDetailsContext);
    const handleFilterOpening = () => {
        setIsOpen((prev) => !prev);
    };


    return (
        <>
            <div className={`${darkMode ? "collapsibleDark" : "bg-light border border-dark"} ${xs}`}>
                <div>
                    <div className="px-2 border-bottom d-flex justify-content-between border-bottom border-dark" onClick={handleFilterOpening} style={{ cursor: "pointer" }}>
                        <h6 className="font-weight-bold my-auto p-1 user-select-none">{title}</h6>
                        <button type="button" className="btn">
                            {!isOpen ? (
                                <FontAwesomeIcon icon={reverseArrow ? faChevronUp : faChevronDown} size="xs" className={`${darkMode ? "collapsibleDark border-0" : ""}`} />
                            ) : (
                                <FontAwesomeIcon icon={reverseArrow ? faChevronDown : faChevronUp} size="xs" className={`${darkMode ? "collapsibleDark  border-0" : ""}`} />
                            )}
                        </button>
                    </div>
                </div>

                <div className="border-bottom">
                    {visibilityBased ?
                        (<div>
                            {<div className={`${isOpen ? "" : "d-none"}`}>{children}</div>}
                        </div>)

                        :
                        <div>{isOpen && <div >{children}</div>}</div>
                    }

                </div>
            </div>
        </>
    );
};

export default Collapsible;