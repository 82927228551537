import React from 'react'
import HashLoader from "react-spinners/HashLoader";

const Loader = () => {

    const override = {
        height: "100vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
    };

    return (
        <div style={override}>
            <HashLoader
                aria-label="Loading Spinner"
                data-testid="loader"
            />
            <p className='fs-5 my-4'>Loading. please wait!</p>
        </div>
    )
}

export default Loader