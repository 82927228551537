import React from 'react'
const ImageTag = (props) => {
    let value = (props.value).toUpperCase()
    if (value === "FREE") {
        return (
            <span className='position-absolute text-light' style={{ borderRadius: "25px", background: "grey", left: "-20px", top: "-7px", fontSize: "11px", padding: "0 5px" }}>{value}</span>
        )
    } else {
        return (
            <span className='position-absolute text-dark' style={{ borderRadius: "25px", background: "#ffc107", left: "-20px", top: "-7px", fontSize: "11px", padding: "0 5px" }}>{value}</span>
        )
    }
}

export default ImageTag