import React from 'react'
import parse from "html-react-parser"

const Tab = (props) => {
    const ParseList = () => {
        let finalTabs = ""
        for (let i = 0; i < props.data.length; i++) {
            finalTabs += `<li className="nav-item" role="presentation">
                    <button className="nav-link active" id="${props.data[i].name}-tab" data-bs-toggle="tab" data-bs-target="#${props.data[i].name}" type="button" role="tab" aria-controls="${props.data[i].name}" aria-selected="true">${props.data[i].name}</button>
                </li>`
        }
        return (
            parse(finalTabs)
        )
    }
    const ParseTabDiv = () => {
        let finalTabs = ""
        for (let i = 0; i < props.data.length; i++) {
            finalTabs += `<div className="tab-pane fade show active" id="${props.data[i].name}" role="tabpanel" aria-labelledby="${props.data[i].name}-tab">${props.data[i].data}</div>`
        }
        return (
            parse(finalTabs)
        )
    }
    return (
        <div className="container py-4">
            <div className="row justify-content-center">
                <ul className="nav nav-pills nav-fill" id="myTab" role="tablist">
                    <ParseList />
                </ul>
                <div className="tab-content" id="myTabContent">
                    {console.log(props.data[0])}
                    <div className="tab-pane fade show active" id={props.data[0].name} role="tabpanel" aria-labelledby={props.data[0].name + "-tab"}>{props.data[0].data}</div>
                    <div className="tab-pane fade " id={props.data[1].name} role="tabpanel" aria-labelledby={props.data[1].name + "-tab"}>{props.data[1].data}</div>
                    <div className="tab-pane fade " id={props.data[0].name} role="tabpanel" aria-labelledby={props.data[0].name + "-tab"}>{props.data[0].data}</div>
                    <ParseTabDiv />
                </div>
            </div>
        </div>
    )
}

export default Tab