import React from 'react'
import Header from '../components/Header'
const Profile = () => {
    return (
        <div>
            <Header />
            <h2>Name: </h2>
            <h2>Username: </h2>
            <h2>Email: </h2>
            <h2>Member Type: </h2>
            <h2>Rooms: Room names add</h2>
            <h2>Saved Environment: </h2>
        </div>
    )
}

export default Profile