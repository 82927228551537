import React, { useContext, useEffect, useState } from "react";
import IDE from "../components/IDE";
import Header from "../components/Header";
import Whiteboard from "../components/Whiteboard";
import { Split } from "@geoffcox/react-splitter";
import Input from "../components/InputBox";
import Output from "../components/OutputBox";
import { API, SOCKET_API } from "../backend";
import { editorDetailsContext } from "../context/GlobalContext";
import apiClient from "../helper/apiClient";
import Collapsible from "../components/collapsable/Collapsible";
import { useParams } from "react-router-dom";
import { io } from "socket.io-client";
import Loader from "../components/loader/Loader";

const Editor = () => {
  const { darkMode, setRoomMeta, setRoomnameState, roomnameState, isLoggedIn, setEditorData, drawings } = useContext(editorDetailsContext);
  const { room } = useParams();
  const [socket, setSocket] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [socketConnected, setSocketConnected] = useState(false)

  useEffect(() => {
    const socket = io(SOCKET_API);
    setSocket(socket);
    console.log(`Connecting socket...`);
    socket.on("connect", () => {
      console.log("connect!!");
      setSocketConnected(true);
    });
    let isRoom = room;
    const controller = new AbortController();


    apiClient
      .get(`${API}/room/${isRoom}`)
      .then(({ data }) => {
        if ((!data.roomExists && !isLoggedIn)) {
          // TODO: SET DATAS
          setRoomMeta({
            roomExists: false,
            owner: "Anonymous",
            roomname: roomnameState,
            status: true,
            roomid: isRoom,
          })
        } else if (data.roomExists && isLoggedIn) {
          // console.log(isLoggedIn)
          try {
            if (data.data.whiteboarddata.length > 0) {
              drawings.current = data.data.whiteboarddata
            }
            setRoomnameState(data.data.roomname);
            setEditorData({
              lang: atob(data.data.lang),
              code: atob(data.data.code),
              args: "",
            })
            setRoomMeta({
              owner: data.data.owner,
              roomname: data.data.roomname,
              roomid: data.data.roomid,
              lang: atob(data.data.lang),
              code: atob(data.data.code),
              whiteboarddata: data.data.whiteboarddata,
              usersmetadata: data.data.usersmetadata,
              validtill: data.data.validtill,
              lastupdate: data.data.updatedAt,
              roomExists: data.roomExists,
              status: true
            })
          } catch (error) {
            console.log(error)
          }

        } else if (data.roomExists && !isLoggedIn) {
          try {
            if (data.data.whiteboarddata.length > 0) {
              drawings.current = data.data.whiteboarddata
            }

            setRoomnameState(data.data.roomname);
            setEditorData({
              lang: atob(data.data.lang),
              code: atob(data.data.code),
              args: "",
            })
            setRoomMeta({
              owner: data.data.owner,
              roomname: data.data.roomname,
              roomid: data.data.roomid,
              lang: atob(data.data.lang),
              code: atob(data.data.code),
              whiteboarddata: (data.data.whiteboarddata),
              usersmetadata: data.data.usersmetadata,
              validtill: data.data.validtill,
              lastupdate: data.data.updatedAt,
              roomExists: data.roomExists,
              status: true
            })
          } catch (error) {
            console.log("server issue", error)
          }

        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      if (socket) {
        console.log(`disconnecting socket...`);
        socket.disconnect();
        controller.abort();
      }
    };
    // eslint-disable-next-line
  }, []);


  return (
    socket && socketConnected && !isLoading ?
      <div className="vh-100 vw-100 overflow-hidden">
        <Header />
        <div className={`${darkMode ? "PrimaryDark" : ""}`}>

          <Split initialPrimarySize="60%"
            minPrimarySize="0px"
            minSecondarySize="0px"
            className="d-block d-md-flex flex-column"
            splitterSize="7px"
            defaultSplitterColors={{
              hover: "rgb(0 122 255/1",
              drag: "rgb(0 122 255/1"
            }}
            resetOnDoubleClick="true"
          >
            <IDE socket={socket} />
            {/* <div> */}
            <div className="vh-100 mx-2 overflow-auto">
              <Collapsible open
                title="Whiteboard" visibilityBased="true"
                xs={"d-none d-md-block"} >
                <Whiteboard socket={socket} />
              </Collapsible >
              <Collapsible close
                title="Input/Output"
                reverseArrow="true"
                visibilityBased="true"
                xs={"z-3 position-absolute bottom-0 start-0 end-0 mx-2"}>
                <div className="d-flex">
                  <Input />
                  <Output />
                </div>
              </Collapsible >
            </div>
          </Split>

        </div>
      </div> : <Loader />
  );
};

export default Editor;
