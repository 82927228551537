import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components';
import Header from '../components/Header';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLeftLong,
} from "@fortawesome/free-solid-svg-icons";

const CustomButton = styled.button`
  padding: 0.5rem 0.8rem;
  background-color: #e5edfe;
  border: none;
  border-radius: 5px;
  color: #2d68f5;
  font-weight: bold;
  font-size: 14px;
  display: inline-block;
  position: relative; 
  margin: 2rem 0px;

  &:after{
    content: '';
    position: absolute;
    width: 80%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 10%;
    background-color: #2d68f5;
    transform-origin: bottom center;
    transition: transform 0.25s ease-out;
    border-radius: 5px;  
  }
  &:hover:after { 
    transform: scaleX(1);
    transform-origin: bottom center;
  }

`;

const PageNotFound = () => {
  let navigate = useNavigate()
  return (
    <>
      <Header />
      <div style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        textAlign: "center",
        width: "90%",
      }}>
        <p
          style={{
            padding: "0.5rem 0.8rem",
            backgroundColor: "#e5edfe",
            borderRadius: "5px",
            color: "#2d68f5",
            fontWeight: "bold",
            fontSize: "14px",
            display: "inline-block"
          }}
        >404 error.</p>
        <h2 style={{
          fontFamily: "Capriola, sans-serif",
          fontWeight: "bold",
          fontSize: "2rem",
        }}>Oh no! We have lost this page.</h2>
        <p>Sorry, the page you are looking for doesn't exist or has been moved.</p>
        <CustomButton
          onClick={() => { navigate("/") }}
        >
          <FontAwesomeIcon icon={faLeftLong} size="lg" className="text-dark mx-1" /> Go Back to HomePage
        </CustomButton>
      </div>
    </>
  )
}

export default PageNotFound