import React, { useEffect, useRef, useContext, useState, useCallback } from "react";
import Editor from "@monaco-editor/react";
import { ClockLoader as Loader } from "react-spinners";
import "../assets/css/Toggle.css";
import { useParams } from "react-router-dom";
import { editorDetailsContext } from "../context/GlobalContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API } from "../backend";
import axios from "axios";
// import ExampleCode from "../helper/ExampleCode";
import langOptions from '../assets/static/langOptions.json'
import apiClient from "../helper/apiClient";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShareNodes,
  faPlay,
  faChalkboardUser,
  faClose
} from "@fortawesome/free-solid-svg-icons";
import Whiteboard from "./Whiteboard";
import SaveData from "./IDE/SaveData";

const IDE = ({ socket }) => {
  const CancelToken = axios.CancelToken;
  //
  const editorRef = useRef("");
  const monacoRef = useRef("");
  const { room } = useParams();
  const langRef = useRef(null);
  const abortControllerRef = useRef(null);
  let isadmin = useRef(false);
  let isWorkingData = useRef(false);
  var users = {};
  var contentWidgets = {};
  const workingData = useRef("");
  let issocket = useRef(false);

  //Global Context
  const { editorData, setEditorData } = useContext(editorDetailsContext);
  const { collabIcons, setCollabIcons } = useContext(editorDetailsContext);
  const { darkMode, roomMeta, inputRef, outputRef } = useContext(editorDetailsContext);
  const { isLoggedIn, authUser } = useContext(editorDetailsContext);
  const { drawings } = useContext(editorDetailsContext);

  // local states 
  const [isWhiteboardOnFullScreen, setIsWhiteboardOnFullScreen] = useState(false)

  // 
  function randomDisplayName() {
    return Math.round(Math.random() * 10000);
  }
  const username = useCallback(() => {
    if (!isLoggedIn) {
      return "Anonymous" + randomDisplayName();
    } else {
      return authUser.username
    }
  }, [authUser.username, isLoggedIn]);

  useEffect(() => {
    // socket.on("connect", () => {
    //   console.log("connect!!");
    // });
    socket.emit("join-room", room, username());

    socket.on("reconnect", () => {
      console.log("reconnect!!");
      socket.emit("join-room", room, username());
    });

    socket.on("admin", function (data) {
      //admin Event
      console.log("Admin initiated");
      isadmin.current = true;
    });

    //
    socket.on("userdata", function (data) {
      //Connected Client Status Event
      var filtered = data.filter(function ({ user }) {
        var key = `${user}`;
        return !this.has(key) && this.add(key);
      }, new Set());

      if (data.length === 1) isadmin.current = true;
      for (var i of data) {
        users[i.user] = i.color;
        insertWidget(i);
      }
      setCollabIcons(filtered);
    });
    //
    socket.on("resetdata", function (data) {
      //get Default Editor Value collab
      // langRef.current = data[0].lang;
      // workingData.current = data[0].code;
      // drawings.current = data[0].drawing
      // isWorkingData.current = true;
      // console.log("resetdata", data)
    });

    socket.on("exit", function (data) {
      toast.info(`${data} Left! 👎`);
      delete users[data];
      console.log(collabIcons)
    });

    window.addEventListener('keydown', (e) => {
      if (((e.metaKey || e.ctrlKey)) && (e.keyCode === 83 || e.key === 's' || e.code === "KeyS")) {
        e.preventDefault();
      }
    });
    return () => {
      if (socket) { socket.disconnect(); setCollabIcons(null); }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //before editor mount
  function handleEditorWillMount(monaco) {
    monacoRef.current = monaco.editor;
    monaco.editor.defineTheme('my-theme', {
      base: 'vs-dark',
      inherit: true,
      rules: [],
      colors: {
        'editor.background': '#282828',
      },
    });
  }

  function handleEditorDidMount(editor) {
    editorRef.current = editor; //save ref for later use
    editor.onDidChangeModelContent(function (e) {
      //Text Change
      if (issocket.current === false) {
        socket.emit("key", e);
      } else {
        issocket.current = false;
      }
    });
    socket.on("editormetadata", function (data) {
      // langRef.current = data.lang
      // setEditorLangData(data.lang);
      setEditorData({ lang: data.lang });
    });
    socket.on("key", function (data) {
      //Change Content Event
      issocket.current = true;
      changeText(data, editor);
    });

    editor.onDidChangeCursorSelection(function (e) {
      //Cursor or Selection Change
      // socket.emit("selection", e); //disable for now
    });

    socket.on("connected", function (data) {
      //Connect New Client Event
      toast.success(`${data.user} Joined! 👍`)
      users[data.user] = data.color;
      insertWidget(data);
      let sendCurrentData = [
        {
          code: editor.getValue(),
          lang: langRef.current,
          drawing: drawings.current
        },
      ];
      socket.emit("filedata", sendCurrentData);
    });

    if (isWorkingData.current) {
      issocket.current = true;
      editor.setValue(workingData.current);
      issocket.current = false;
    }

    // editor.focus();
  }
  function changeText(e, editor) {
    editor.getModel().applyEdits(e.changes); //change Content
  }

  function insertWidget(e) {
    contentWidgets[e.user] = {
      domNode: null,
      position: {
        lineNumber: 0,
        column: 0,
      },
      getId: function () {
        return "content." + e.user;
      },
      getDomNode: function () {
        if (!this.domNode) {
          this.domNode = document.createElement("div");
          this.domNode.innerHTML = e.user;
          this.domNode.style.background = e.color;
          this.domNode.style.color = "black";
          this.domNode.style.opacity = 1;
          this.domNode.style.width = "max-content";
        }
        return this.domNode;
      },
      getPosition: function () {
        console.log("thispositio", this.position);
        return {
          position: this.position,
          preference: [
            monacoRef.current.ContentWidgetPositionPreference.ABOVE,
            monacoRef.current.ContentWidgetPositionPreference.BELOW,
          ],
        };
      },
    };
  }
  // run on click "RUN" btn
  const runCode = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.cancel('Operation canceled by the user.');
    }
    let outputBox = outputRef.current;
    outputBox.value = "";
    let encodedCode = btoa(editorRef.current.getValue());
    let encodedArgs = btoa(inputRef.current.value);
    let encodedLang = btoa(editorData.lang);

    let params = {
      code: encodedCode,
      args: encodedArgs,
      lang: encodedLang,
      url: room
    }

    abortControllerRef.current = CancelToken.source();

    const loadingResponse = toast.loading("Code submitted! Please wait...", { theme: "dark" })

    apiClient
      .post(`${API}/code`, params, { cancelToken: abortControllerRef.current.token })
      .then((response) => {
        let data = response.data;
        if (data.status === false) {
          if (data.output.code === 3221225725) {
            outputBox.value = `Segmentation Fault!`;
            toast.update(loadingResponse, { render: "Cannot compile code! 😶", type: "error", isLoading: false, autoClose: 2000, theme: "dark", pauseOnFocusLoss: false });
          }
          else {
            outputBox.value = `Error: ${data.output.code}`;
            toast.update(loadingResponse, { render: "Cannot compile code! 😶", type: "error", isLoading: false, autoClose: 2000, theme: "dark", pauseOnFocusLoss: false });
          }

        } else {
          var codeOutput = atob(data.output);
          // var MemUsage = response.data.memoryUsage;
          // var cpuUsage = response.data.cpuUsage;
          // outputBox.value = `${codeOutput} \n\n ..............\n Mem Usage: ${Math.abs(Math.round(MemUsage / 1024 / 1024 * 100) / 100)} MB \n CPU Usage: ${cpuUsage}`;
          outputBox.value = `${codeOutput}`;
          toast.update(loadingResponse, { render: "Response Recieved! 😄", type: "success", isLoading: false, autoClose: 2000, theme: "dark", pauseOnFocusLoss: false });
        }

      })
      .catch((err) => {
        toast.update(loadingResponse, { render: "Cannot compile code! 😶", type: "error", isLoading: false, autoClose: 2000, theme: "dark", pauseOnFocusLoss: false });
      });
  }



  const handleEditorChange = (value) => {
    localStorage.setItem(room, JSON.stringify(value))
  }



  // TODO: set langRef as well
  const defaultEditorValue = () => {
    if (roomMeta.status && roomMeta.roomExists && roomMeta.code.length > 0) {
      langRef.current = (roomMeta.lang) || "";
      // setEditorData({ lang: atob(roomMeta.lang) });
      return (roomMeta.code);
    }
    else {
      return "Welcome to codeite! Select the language and start coding!"
    }
  }

  // select html
  const onChangeSelectLang = (e) => {
    setEditorData({ lang: e.target.value });
    localStorage.setItem("lang", JSON.stringify(e.target.value))
    // setEditorLangData(e.target.value)
    socket.emit("editormetadata", { lang: e.target.value });
  }

  const WhiteboardOnFullScreen = () => {
    return (
      <div className={`${darkMode ? "collapsibleDark" : "bg-light border border-dark"}  position-fixed vh-100 vw-100 botton-0 top-0 start-0 end-0`}>
        <div className="px-2 border-bottom d-flex border-bottom border-dark justify-content-between align-items-center">
          <h6 className="font-weight-bold my-auto p-1 user-select-none">WHITEBOARD</h6>
          <button
            data-bs-toggle="tooltip" data-bs-placement="bottom" title="Close Whiteboard"
            type="button"
            className={`border border-dark btn btn-outline-dark px-3 py-1 text-nowrap mx-1 rounded-0 ${darkMode ? "white-btn" : ""
              }`}
            onClick={() => { setIsWhiteboardOnFullScreen(!isWhiteboardOnFullScreen) }}
          >
            Close <FontAwesomeIcon icon={faClose} size="lg" />
          </button>
        </div>

        <Whiteboard socket={socket} />
      </div>
    )
  }

  return (
    <>
      {/* <button onClick={() => { console.log(langRef.current) }}>langRef</button> */}
      <div
        className="container-fluid IDE py-2 px-2"
        style={{ width: "100%" }}
      >
        <div className="d-flex justify-content-between" >
          <div className="container d-flex justify-content-start p-1">
            <div className=" d-flex" style={{ paddingLeft: "0px" }}>
              <select
                className={`form-select ${darkMode ? "white-dropdown" : ""}`}
                style={{
                  width: "100%",
                  border: "1px solid black",
                  borderRadius: "0",
                  fontSize: "14px",
                }}
                aria-label="Default select example"
                name="lang"
                // defaultValue={defaultSelectVal()}
                ref={langRef}
                onChange={onChangeSelectLang}
                value={editorData.lang}
              >
                <option value="default">Language</option>
                {
                  langOptions.map((lang) => {
                    return (
                      <option key={lang.name} value={lang.value}>{lang.name}</option>
                    )
                  })
                }
              </select>
            </div>
            <button
              data-bs-toggle="tooltip" data-bs-placement="bottom" title="Run Code"
              type="button"
              className={`btn btn-outline-dark px-3 py-1 text-nowrap mx-1 rounded-0 ${darkMode ? "white-btn" : ""
                }`}
              style={{
                border: "1px solid black",
                fontSize: "14px",
                boxShadow: "none",
              }}
              onClick={() => {
                // setSendCode(sendCode + 1);
                runCode()
              }}
            >
              Run <FontAwesomeIcon icon={faPlay} />
            </button>
            <button
              data-bs-toggle="tooltip" data-bs-placement="bottom" title="Share with friends"
              type="button"
              className={`btn btn-outline-dark px-3 py-1 text-nowrap mx-1 rounded-0 ${darkMode ? "white-btn" : ""
                }`}
              style={{
                border: "1px solid black",
                fontSize: "14px",
                boxShadow: "none",
              }}
              onClick={() => {
                navigator.clipboard.writeText(window.location.href);
                toast.success(`Link Copied!👍`, { autoClose: 1000, theme: "dark", pauseOnFocusLoss: false })
              }}
            >
              <FontAwesomeIcon icon={faShareNodes} size="lg" />
            </button>
            {/* whiteboard on small screens */}
            <button
              data-bs-toggle="tooltip" data-bs-placement="bottom" title="Open Whiteboard"
              type="button"
              className={`d-block d-md-none btn btn-outline-dark px-3 py-1 text-nowrap mx-1 rounded-0 ${darkMode ? "white-btn" : ""
                }`}
              style={{
                border: "1px solid black",
                fontSize: "14px",
                boxShadow: "none",
              }}
              onClick={() => { setIsWhiteboardOnFullScreen(!isWhiteboardOnFullScreen) }}
            >
              Whiteboard <FontAwesomeIcon icon={faChalkboardUser} size="lg" />
            </button>

          </div>

          {isLoggedIn &&
            <SaveData editorRef={editorRef} drawings={drawings} langRef={langRef} />
          }
        </div>
        {/*  */}

        <div style={{ maxHeight: 'calc(100vh)', overflowY: 'auto', border: '1px solid #ccc' }}>
          <Editor
            // height="calc(100vh - 65px)"
            height='calc(95vh - 130px)'
            theme={darkMode ? "my-theme" : "vs"}
            language={editorData.lang}
            // language={defaultEditorLang}
            beforeMount={handleEditorWillMount}
            onMount={handleEditorDidMount}
            value={defaultEditorValue()}
            loading={<Loader />}
            onChange={handleEditorChange}
            className="z-0"
            options={{
              "formatOnPaste": true,
              scrollbar: {
                useNative: false, // Use built-in scrollbar
              },
            }}
          />
        </div>
      </div>
      <ToastContainer pauseOnFocusLoss={false} />
      {/* full screen editor */}
      {
        isWhiteboardOnFullScreen &&
        <WhiteboardOnFullScreen />
      }

    </>
  );
};

export default IDE;
