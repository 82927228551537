import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import CollaborationIcons from "./CollaborationIcons";
import { editorDetailsContext } from "../context/GlobalContext";
import defaultAvatar from '../assets/img/default-avatar.jpg'
import ImageTag from "./membership/ImageTag";
import { delete_cookie } from "../helper/helpFunc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSun,
  faMoon
} from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  let isRoom = window.location.pathname.split("/")[1] === 'room'
  const { darkMode, setDarkMode, scroll } = useContext(editorDetailsContext);

  const { isLoggedIn, setIsLoggedIn, roomnameState, authUser } = useContext(editorDetailsContext);
  const executeScroll = () => {
    scroll.current.scrollIntoView()
  }

  useEffect(() => {
    if (localStorage.getItem("mode") === "dark") {
      setDarkMode(true);
    }
  }, [setDarkMode])


  function randomDisplayName() {
    return Math.round(Math.random() * 10000);
  }
  const username = () => {
    if (!isLoggedIn) {
      return "Anonymous" + randomDisplayName();
    } else {
      return authUser.username || "Anonymous" + randomDisplayName();;
    }
  };

  const logout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('refreshtoken');
    localStorage.removeItem('token')
    localStorage.removeItem('name')
    delete_cookie("token")
  }

  const EditorTheme = () => {
    if (!darkMode) {
      localStorage.setItem("mode", "dark");
      setDarkMode(true);
    } else {
      localStorage.setItem("mode", "light");
      setDarkMode(false);
    }
  };

  return (
    <>
      <div>
        <nav
          className={`navbar navbar-expand-lg navbar-light shadow-sm ${darkMode ? "navDark" : ""
            }`}
          style={{ backgroundColor: "#fff" }}
        >
          <div className="container-fluid">

            <Link to="/" className={`navbar-brand  mx-3 my-1 h5 ${darkMode ? "textColor" : ""
              }`}>
              {(isRoom) ? "</>" : "<Code-ite/>"}
            </Link>


            {
              isRoom ? <div className="d-flex flex-column">
                <h2 className={` ${darkMode ? "text-light" : "text-dark"} my-auto fs-5`}>{`${window.location.pathname.split("/")[2].split("-")[0]}`}</h2>

                <p className={` ${darkMode ? "text-light" : "text-dark"} my-auto fs-6`}>{`${roomnameState}`}</p>
                {/* <input type="text" name="roomname" ref={roomnameRef} className={` ${darkMode ? "text-light" : "text-dark"} my-auto fs-6`} /> */}


              </div> : ""
            }
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span
                className={`navbar-toggler-icon ${darkMode ? "navToggleIcon" : ""
                  }`}
              ></span>
            </button>
            {isLoggedIn ? (
              <>
                <div
                  className=" collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <div className=" ms-auto d-flex justify-content-end">
                    <CollaborationIcons />
                    <span className="d-flex mx-3 my-1 h5">
                      <h6 className={`d-flex align-items-center mx-3 my-1 h6 ${darkMode ? "textColor" : ""
                        }`}>{(isRoom) ? `${username()}` : ""}</h6>
                    </span>
                    <div className="dropstart">

                      <img src={defaultAvatar} className="rounded-circle dropdown-toggle" height={35} alt="..." role="button" data-bs-toggle="dropdown" aria-expanded="false"></img>
                      <ImageTag value="pro" />
                      {/* profile navbar icon */}
                      <ul className={` ${darkMode ? "dropdown-menu dropdown-menu-dark" : "dropdown-menu"
                        }`}>
                        <li><a className="dropdown-item" href="/profile">Profile</a></li>
                        <li><a className="dropdown-item" href="/settings">Settings </a></li>
                        <li><button onClick={() => { logout() }} className={`dropdown-item`}>Signout</button></li>
                      </ul>
                    </div>
                  </div>
                </div>

              </>
            ) : (
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                {window.location.pathname === "/" ?
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0 ">
                    <a href="https://github.com/namankhare/code-ite/issues" target="_blank" rel="noreferrer" referrerPolicy="origin">
                      <li className="nav-item">
                        <span className={`nav-link mx-3 my-1 h5 ${darkMode ? "textColor" : ""
                          }`}>Support</span>
                      </li>
                    </a>
                    <Link to="/#about" onClick={() => {
                      executeScroll()
                    }}>
                      <li className="nav-item">
                        <span className={`nav-link mx-3 my-1 h5 ${darkMode ? "textColor" : ""
                          }`}>About</span>
                      </li>
                    </Link>
                  </ul> : ""}

                <form className="d-flex ms-auto" >
                  <CollaborationIcons />
                  <span className="d-flex mx-3 my-1 h5">
                    <h6 className={`d-flex align-items-center mx-3 my-1 h6 ${darkMode ? "textColor" : ""
                      }`}>{window.location.pathname.split("/")[1] === 'room' ? `${username()}` : ""}</h6>
                  </span>
                  <Link to="/login" className="me-4 ">

                    <button
                      type="button"
                      className={`btn btn-outline-dark text-nowrap px-4 py-2 rounded-0 ${darkMode ? "white-btn" : ""
                        }`}
                    >
                      Login
                    </button>
                  </Link>

                  {/* <Link to="/signup">
                    <button
                      type="button"
                      className={`btn btn-dark text-nowrap px-4 py-2 rounded-0 ${darkMode ? "black-btn" : ""
                        }`}
                    >
                      Sign Up
                    </button>
                  </Link> */}
                </form>

              </div>
            )}
            {/* theme switcher */}
            {/* <FontAwesomeIcon icon="fa-solid fa-sun-bright" /> */}
            {/* <FontAwesomeIcon icon="fa-solid fa-moon" /> */}

            {/* <label className="switch pt-2">
              <input
                type="checkbox"
                ref={darkToggleRef}
                onChange={() => {
                  EditorTheme();
                }}
              />
              <span className="slider round"></span>
            </label>  */}

            <button
              onClick={() => {
                EditorTheme();
              }}

              className={`${darkMode ? "bg-dark" : "bg-light"} border-0`}
            >
              {
                darkMode ? (<FontAwesomeIcon icon={faSun} size="lg" className="text-light" />) : (<FontAwesomeIcon icon={faMoon} size="lg" className="text-dark" />)
              }
            </button>

            {/* <p>hello</p> */}
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;
