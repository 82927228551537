// import axios from "axios";
import { useState, createContext, useRef } from "react";
// import { API, SOCKET_API } from "../backend";
// import { io } from "socket.io-client";

const initialEditorData = {
  lang: "",
  args: "",
  code: "",
};
const userDetails = {
  id: '',
  firstName: '',
  memberType: '',
  email: '',
  username: ''
};

const roomMetaInfo = {
  owner: '',
  roomname: '',
  roomid: '',
  lang: '',
  code: '',
  whiteboarddata: '',
  usersmetadata: [],
  validtill: '',
  lastupdate: '',
  roomExists: false,
  status: false
}

export const editorDetailsContext = createContext(null);
// export const loginState = createContext(null);

const defaultColorMode = () => {
  if (
    localStorage.getItem("mode") === "dark" ||
    localStorage.getItem("mode") === "light"
  ) {
    return localStorage.getItem("mode") === "dark" ? true : false;
  } else {
    let currentMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
    localStorage.setItem("mode", currentMode ? "dark" : "light");
    return currentMode;
  }
};

const ContextProvider = ({ children }) => {
  //global state
  const [editorData, setEditorData] = useState(initialEditorData);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [collabIcons, setCollabIcons] = useState(null);
  const [darkMode, setDarkMode] = useState(defaultColorMode);
  const [roomMeta, setRoomMeta] = useState(roomMetaInfo);
  const [authUser, setAuthUser] = useState(userDetails)
  const [roomnameState, setRoomnameState] = useState("Anonymous room")
  const [isLoading, setIsLoading] = useState(true);

  //global ref
  const darkToggleRef = useRef(null);
  const scroll = useRef(null);
  const drawings = useRef([]);
  const inputRef = useRef("");
  const outputRef = useRef("");


  // global socket conn
  // const socket = io(SOCKET_API);

  return (
    <editorDetailsContext.Provider
      value={{
        editorData,
        setEditorData,
        isLoggedIn,
        setIsLoggedIn,
        collabIcons,
        setCollabIcons,
        darkToggleRef,
        darkMode,
        setDarkMode,
        scroll,
        drawings,
        roomMeta,
        setRoomMeta,
        authUser,
        setAuthUser,
        // socket,
        inputRef,
        outputRef,
        roomnameState,
        setRoomnameState,
        isLoading,
        setIsLoading
      }}
    >
      {children}
    </editorDetailsContext.Provider>
  );
};

export default ContextProvider;
