import React from 'react'
import Header from '../components/Header'
import Tab from '../components/tabs/Tab'

const Settings = () => {
    const staticData = [
        {
            "name": "profile",
            "data": "profile data"
        },
        {
            "name": "plans",
            "data": "plans data"
        },
        {
            "name": "email prefrence",
            "data": "email data"
        },
        {
            "name": "settings",
            "data": "settins data"
        }
    ]
    return (
        <>
            <Header />
            <Tab data={staticData} />
        </>
    )
}

export default Settings