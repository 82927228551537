import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import ContextProvider from './context/GlobalContext';
import ReactGA from "react-ga4";

// google analytics
ReactGA.initialize("UA-69300753-7");


if (process.env.REACT_APP_BACKEND !== 'http://localhost:5001/api') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}

ReactDOM.render(
  <ContextProvider>
    <App />
  </ContextProvider>,
  document.getElementById('root')
);

