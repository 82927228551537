import React, { useContext, useEffect, useState } from 'react'
import { editorDetailsContext } from '../../context/GlobalContext';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSave
} from "@fortawesome/free-solid-svg-icons";
import { fromNow } from '../../helper/helpFunc';
import apiClient from '../../helper/apiClient';
import { useParams } from 'react-router-dom';
import { API } from '../../backend';
import 'react-toastify/dist/ReactToastify.css';


const SaveData = ({ editorRef, drawings, langRef }) => {
    const { isLoggedIn, darkMode, authUser, roomMeta, editorData, setRoomMeta } = useContext(editorDetailsContext);


    // useState
    const [saveStatus, setSaveStatus] = useState("saved");
    const [saveTime, setSaveTime] = useState(roomMeta.lastupdate);
    const [convertSaveTime, setConvertSaveTime] = useState(null);

    // RRD
    const { room } = useParams();

    const controller = new AbortController();
    const config = {
        signal: controller.signal
    };

    const saveDataToServer = () => {
        if (!isLoggedIn) {
            toast.info("You need to be logged in!", { theme: "dark" })
            return;
        } else {
            setSaveStatus("saving..")
        }

        console.log(isLoggedIn, roomMeta.status, roomMeta.owner, authUser.id, roomMeta)
        if (isLoggedIn && roomMeta.status && (roomMeta.owner === authUser.id)) {
            try {
                let encodedCode = btoa(editorRef.current.getValue());
                let encodedDrawing = drawings.current
                let encodedLang = btoa(editorData.lang || langRef.current);
                let isRoom = room;
                let data = {
                    code: encodedCode,
                    whiteboard: encodedDrawing,
                    lang: encodedLang,
                    roomid: isRoom,
                    ownerid: roomMeta.owner
                }
                apiClient
                    .post(`${API}/room/autosave`, data, config)
                    .then(function ({ data }) {
                        console.log("new date:", data)
                        setSaveTime(data.msg.updatedAt)
                        setRoomMeta({ ...roomMeta }, {
                            code: atob(data.msg.code),
                            lang: atob(data.msg.lang),
                            whiteboarddata: data.msg.whiteboarddata,
                            validtill: data.msg.validtill,
                            usersmetadata: data.msg.usersmetadata,
                            lastupdate: data.msg.updatedAt
                        })
                        setSaveStatus("saved");
                    })
                    .catch(function (error) {
                        setSaveStatus("error saving!");
                        setTimeout(() => {
                            setSaveStatus("saved");
                        }, 2000);
                    });
            } catch (error) {
                console.log(error)
            }
        } else {

        }
    }
    useEffect(() => {
        let timeInterval = setInterval(() => {
            setConvertSaveTime(fromNow(new Date(saveTime)))
        }, 1000);

        return () => {
            clearInterval(timeInterval)
        }
    }, [saveTime])

    return (
        <div className="container d-flex justify-content-end">
            <p className={`${darkMode ? "text-white" : "text-dark"} my-auto`}>Last saved: <span className="fw-light">{convertSaveTime}</span> <span className={`${darkMode ? "text-white" : "text-dark"} my-auto  fst-italic fw-light`}>{saveStatus === "saved" ? (<FontAwesomeIcon data-bs-toggle="tooltip" data-bs-placement="bottom" title="Click to save" icon={faSave} onClick={saveDataToServer} size="lg" style={{ "cursor": "pointer" }} className={`${darkMode ? "collapsibleDark border-0" : ""}`} />) : saveStatus}</span></p>
            <ToastContainer pauseOnFocusLoss={false} />
        </div>
    )
}
export default SaveData