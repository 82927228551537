import React, { useRef, useContext, useEffect } from "react";
import { API } from "../backend";
import backArrow from "../assets/svg/backArrow.svg";
import poster from "../assets/svg/poster.svg";
import { Link, useNavigate } from "react-router-dom";
import { editorDetailsContext } from "../context/GlobalContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import apiClient from "../helper/apiClient";


const Login = () => {
  const email = useRef("");
  const password = useRef("");

  const { setIsLoggedIn, isLoggedIn, setAuthUser } = useContext(editorDetailsContext);
  let navigate = useNavigate();
  useEffect(() => {
    if (isLoggedIn === true) {
      navigate('/');
    }
  }, [navigate, isLoggedIn]);

  const loginReq = () => {
    const sendEmail = email.current.value;
    const sendPassword = password.current.value;
    const loginResponse = toast.loading("Please wait...")
    apiClient
      .post(`${API}/auth/signin`, {
        email: sendEmail,
        password: sendPassword,
      }, {
        withCredentials: true
      })
      .then(function (response) {
        if (response.data.error === true) {
          toast.update(loginResponse, { render: response.data.user, type: "error", isLoading: false, autoClose: 3500, theme: "dark", closeOnClick: true, draggable: true });
          return;
        } else {
          toast.update(loginResponse, { render: "Login Successful! 😄", type: "success", isLoading: false, autoClose: 3500, theme: "dark", closeOnClick: true, draggable: true });
          setIsLoggedIn(true);
          localStorage.setItem("refreshtoken", response.data.refreshToken);
          localStorage.setItem('name', response.data.user.firstName);
          localStorage.setItem('token', response.data.token);
          console.log("login", response.data)
          setAuthUser({
            id: response.data.user.id,
            firstName: response.data.user.firstName,
            memberType: response.data.user.memberType,
            email: response.data.user.email,
            username: response.data.user.username
          })
          setTimeout(() => {
            navigate('/')
          }, 2000);
        }
      })
      .catch(function (error) {
        console.log("error", error);
        toast.update(loginResponse, { render: "Login Failed! 😛", type: "error", isLoading: false, autoClose: 3500, theme: "dark", closeOnClick: true, draggable: true });
        setIsLoggedIn(false);
        setAuthUser({
          firstName: '',
          memberType: '',
          email: '',
          username: ''
        });
      });
  };

  return (
    <div className="p-0 m-0">
      <nav
        className="d-flex justify-content-between"
        style={{ padding: "25px 50px" }}
      >
        <button onClick={() => navigate(-1)} style={{ width: "50px" }} className="btn btn-outline-light"><img
          src={backArrow}
          alt="arrow"
          className="backArrow"
          style={{ width: "20px" }}
        /></button>
        <h3 onClick={() => navigate('/')} className="btn btn-light fs-5">{"<Code-ite />"}</h3>
      </nav>
      <div className="container">
        <div className="container-fluid d-flex pt-4 mt-4">
          <div className="px- mx-5 pt-5" style={{ width: "60%" }}>
            <form className="LoginForm" style={{ paddingTop: "30px" }}>
              <h1 className=" fs-2">Login</h1>
              <div className="mb-3 ">
                <label htmlFor="InputEmail" className="no-textwrap py-2">
                  Email address
                </label>
                <input
                  type="email"
                  className="form-control mt-1 py-2 rounded-0  shadow bg-body rounded"
                  id="InputEmail"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  ref={email}
                />
              </div>
              <div className="mb-4 ">
                <label htmlFor="InputPassword" className="no-textwrap py-2">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control mt-1 py-2 rounded-0 shadow bg-body rounded"
                  id="InputPassword"
                  aria-describedby="passwordHelp"
                  placeholder="Password"
                  ref={password}
                />
              </div>

              <div className="rightSec d-flex justify-content-start">
                <div>
                  <input
                    type="button"
                    onClick={() => {
                      loginReq();
                    }}
                    value="Login"
                    className=" btn btn-dark m-4 ms-0 px-5 py-2 rounded-0"
                  />
                </div>
                <div>
                  <Link to="/signup">
                    <button
                      type="button"
                      className={`btn btn-outline-dark my-4 ms-0 px-5 py-2 rounded-0 `}
                    >
                      Sign Up
                    </button>
                  </Link>
                </div>
              </div>
            </form>
            {/* <h3 className=" fs-6 text-center ">{login}</h3> */}
          </div>
          <div className="justify-content-center d-none d-md-block mx-5 px-5 pt-5">
            <img
              src={poster}
              alt="back"
              width="80%"
              height="100%"
              style={{ marginLeft: "20px" }}
            />
          </div>
        </div>
      </div>
      <ToastContainer pauseOnFocusLoss="false" />
    </div>
  );
};

export default Login;
