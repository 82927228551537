import React, { useCallback, useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./core/Home";
import Login from "./core/Login";
import Signup from "./core/Signup";
import Editor from "./core/Editor";
import Settings from "./core/Settings";
import { v4 as uuidv4 } from "uuid";
import { editorDetailsContext } from "./context/GlobalContext";
import "../src/assets/css/DarkMode.css";
import Profile from "./core/Profile";
import axios from "axios";
import PageNotFound from "./core/PageNotFound";
// import ComingSoon from "./components/ComingSoon"; 
function App() {
  const { setIsLoggedIn, setAuthUser, setIsLoading } = useContext(editorDetailsContext);

  const refreshAuthState = useCallback(async () => {
    let getRefreshToken = localStorage.getItem("refreshtoken");
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND}/auth/refreshauthstate`, { refreshToken: getRefreshToken },
      {
        withCredentials: true,
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
      }
    );
    if (data.status === true) {
      // console.log("refreshauth", data)
      setIsLoggedIn(true);
      setAuthUser({
        id: data.user.id,
        firstName: data.user.firstName,
        memberType: data.user.memberType,
        email: data.user.email,
        username: data.user.username
      })
    } else {
      setIsLoggedIn(false);
      setAuthUser({
        id: '',
        firstName: '',
        memberType: '',
        email: '',
        username: ''
      })

    }
  }, [setAuthUser, setIsLoggedIn])

  useEffect(() => {
    refreshAuthState();
    setIsLoading(false);
  }, [refreshAuthState, setIsLoading])


  return (<Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="login" element={<Login />} />
      <Route path="signup" element={<Signup />} />
      <Route
        path="join"
        element={<Navigate replace to={`/room/${uuidv4()}`} />}
      />
      <Route path="room" element={<Navigate replace to="/" />} />
      <Route path="room/:room" element={<Editor />} />
      <Route path="profile" element={<Profile />} />
      <Route path="settings" element={<Settings />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  </Router>
  );
}

export default App;
